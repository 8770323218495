.container {
  padding-inline: 52px;
}

/* Input Field  */

.inputFieldStyle input,
.inputFieldErrorStyle > input,
.inputFieldSuccessStyle input {
  border: 1px solid #5b5b5b;
  border-radius: 6px;
  background: transparent;
  height: '46px';
  color: #b1b1b1;
  padding: 15px;
  width: 452px;
}

.inputFieldStyle input {
  border: 1px solid #5b5b5b;
  border-radius: 6px;
  background: transparent;
  height: '46px';
  color: #b1b1b1;
  padding: 15px;
  /* margin-top: 10px; */
}

.inputFieldStyle input:focus {
  background: #5ca8ff2d;
  border: 1px solid #5ca8ff;
}

.inputFieldLabelStyle {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #f1f1f1;
}

.inputFieldErrorStyle input {
  border: 1px solid #ad0404;
  background-color: rgba(196, 110, 110, 0.12);
  color: #ffffff;
  padding: 15px;
}

.buttonStyle {
  padding: 10px 30px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: all 0.2s;
  border-radius: 6px;
  background: #5ca8ff;
  width: 452px;
  height: 56px;
  color: #ffff;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.buttonStyle a {
  color: #fff;
}

/* ------------ Primary Button ------------- */
/* .buttonStyle {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  min-width: 100px;
  height: 50px;
  border-radius: 8px;
  font-weight: 600;
  background-color: var(--white-color);
  border: 1px solid var(--mint-green-color);
  transition: all 0.2s ease;
  user-select: none;
  color: var(--blackish-green-color);
  overflow: hidden;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}
.buttonStyle:hover {
  border: unset;
  background-color: var(--mint-green-color);
}

.buttonStyle:active {
  scale: 1.05;
} */
/* ------------ Secondary Button ------------- */
.darkBtn {
  border: unset;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(255, 255, 255, 0.1);
}

.darkBtn:hover {
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.darkBtn:hover > a {
  color: #000;
}

/* ------------ Light Button ------------- */
.lightBtn {
  border: unset;
  border: 1px solid rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 1);
}

.lightBtn > a {
  color: var(--green-color);
}

.lightBtn:hover {
  background: var(--green-gradient);
  /* border: 1px solid rgba(255, 255, 255, 0.12); */
}

.lightBtn:hover > a {
  color: #fff;
}
/* ----------- Colored Button ------------ */
.coloredBtn {
  background-color: var(--blackish-green-color);
  text-transform: capitalize;
  border: 1px solid var(--blackish-green-color);
  font-weight: 600;
}
.coloredBtn {
  color: var(--white-color);
}
.coloredBtn:hover {
  background-color: var(--white-color);
  border: 1px solid var(--blackish-green-color);
}
.coloredBtn:hover > a {
  color: var(--light-blue);
}

/* Reverse BTN  */

.coloredReverseBtn {
  background-color: var(--mint-green-color);
  text-transform: capitalize;
  font-weight: 600;
  color: var(--blackish-green-color);
}

.coloredReverseBtn:hover {
  background-color: var(--white-color);
  border: 1px solid var(--mint-green-color);
  color: var(--mint-green-color);
}

.coloredReverseBtn:hover > a {
  color: red;
}

/* ------- Disabled Button ------- */
.buttonStyleDisabled {
  background-color: var(--disabled-btn-color);
  border: unset;
}
.buttonStyleDisabled > a {
  cursor: not-allowed;
  background-color: var(--disabled-btn-color);
  color: var(--disabled-btn-text-color);
}
.buttonStyleDisabled:hover {
  background-color: var(--disabled-btn-color);
}
.buttonStyleDisabled:hover > a {
  background-color: var(--disabled-btn-color);
  color: var(--disabled-btn-text-color);
}
.buttonStyleDisabled:active {
  scale: 1;
}

@media screen and (max-width: 768px) {
  .container {
    padding-inline: 15px;
  }
}

@media screen and (max-width: 580px) {
  .inputFieldStyle input,
  .inputFieldErrorStyle > input,
  .inputFieldSuccessStyle input {
    max-width: 452px;
    width: 100%;
  }
  .buttonStyle {
    max-width: 452px;
    max-height: 56px;
    height: 100%;
    width: 100%;
  }
}
