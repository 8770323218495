.wrapper {
    padding: 0 40px;
  }
  
  .extraPadding {
    padding: 0 100px;
  }
  
  @media screen and (max-width: 992px) {
    .wrapper,
    .extraPadding {
      padding: 0 30px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .wrapper,
    .extraPadding {
      padding: 0 20px;
    }
  }
  