.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80dvh;
}

.notFound h1 {
  color: var(--white-color);
}

.notFound h3 {
  color: rgba(255, 255, 255, 0.64);
}

.notFoundImage {
  width: 100%;
  max-width: 700px;
}

.topOf {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topOf img {
  width: 178px;
  max-height: 54px;
}

.backBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 35px;
  color: #fff;
  transition: color 0.3s ease;
}

.backBtn:hover {
  color: var(--blue-color);
}

.backBtn p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

@media screen and (max-width: 576px) {
  .topOf {
    flex-direction: column;
    gap: 30px;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(5px, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate(-5px, 0);
  }
  25%,
  75% {
    transform: translate(0, 5px);
  }
  45%,
  55% {
    transform: translate(0, -5px);
  }
}
