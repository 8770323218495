.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1.5rem;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footerWrapper p {
  color: #ffffff;
}

@media screen and (max-width: 425px) {
  .footerWrapper {
    justify-content: center;
  }
}
