@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

html {
  font-size: 16px;
  background: var(--white-color);
}

:root {
  --white-color: #fff;
}

/* SCROLLBAR Customizations */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: rgba(0, 0, 0, 0);
  width: 20px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  background-color: #000;
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  width: 100%;
  min-height: 100dvh;
  background-image: url("../images/bg-1.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-style: normal;
  font-weight: 400px;
  overflow: hidden;
  color: var(--white-color);
}

img {
  -webkit-user-drag: none;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  font-size: 1rem;
}
h1 {
  font-size: 3rem; /* 48px */
}
h2 {
  font-size: 2.25rem; /* 36px */
}
h3 {
  font-size: 1.375rem; /* 22px */
}
h4 {
  font-size: 1.25rem; /* 20px */
}
h5 {
  font-size: 1.125rem; /* 18px */
}
h6 {
  font-size: 1rem; /* 16px */
}
p {
  font-size: 0.875rem; /* 14px */
}
ul {
  list-style: none;
  padding: 0;
}

@media screen and (max-width: 991px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 567px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 10px;
  }
}
