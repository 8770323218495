.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100dvh;
  position: relative;
  z-index: 11111111111111111111;
}
.logoImg {
  margin-top: 50px;
  position: absolute;
  top: 0px;
}

.mainContent {
  position: relative;
  text-align: center;
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainHead,
.mainHead span {
  font-size: 4.25rem;
  font-weight: 400;
  margin-bottom: 10px;
  max-width: 950px;
}

.mainHead span {
  background: linear-gradient(96deg, #7eb2ff 7.29%, #546fff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subHead {
  font-size: 1.5rem;
  font-weight: 400;
  max-width: 1000px;
  text-align: center;
  margin-bottom: 50px;
}

.buttonPt {
  color: #fff;
  cursor: pointer;
  background-color: #5ca8ff;
  padding: 17px 34px;
  border-radius: 8px;
  transition: 0.35s;
  font-size: 1rem;
}

.homniLink {
  color: #5ca8ff;
  font-size: 1.5rem;
}

.buttonPt:hover {
  background-color: #1f3a62;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 70px;
  margin-block: 25px;
  max-width: 1440px;
  padding-inline: 40px;
}

.supportBtn {
  border-radius: 30px 8px 30px 30px;
  background-color: rgb(255, 255, 255);
  z-index: 2147483647;
  cursor: pointer;
  padding: 10px 25px;
  transition: all 0.3s ease-out 0s;
  color: #000000;
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 1.125rem;
}

.supportBtn:hover {
  background-color: #c6e0ff;
}

@media screen and (max-width: 576px) {
  .wrapper {
    min-height: 95dvh;
  }
  .mainHead,
  .mainHead span {
    font-size: 2.5rem;
  }
  .subHead {
    font-weight: 200;
    font-size: 1.1rem;
  }
  .homniLink {
    color: #5ca8ff;
    font-size: 1.1rem;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: unset;
  }
  .supportBtn {
    border-radius: 30px;
  }
}
